import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {useGlobalState} from "../GlobalState";
import {apiPath} from "../utils/apiUrl";
import {ErrorResponse, UserInfo} from "../interfaces/types";
import {httpGet, httpPost} from "../utils/fetch-client";
import {AuthenticatorListDateProps, AuthenticatorListEntryProps, enforceFidoType, FidoGroupProps,} from "../../../shared/interfaces";
import {Modal} from "designsystem/src/components/Modal/Modal";
import {I18NParagraph} from "designsystem/src/components/I18NParagraph/I18NParagraph";
import {OnlyOneAuthInfo} from "designsystem/src/components/Modal/OnlyOneAuthInfo/OnlyOneAuthInfo";
import {identifyPlatformByDeviceName} from "designsystem/src/utils";
import {signInFunction} from "./LoginPage";
import {RadioButtonGroup} from "designsystem/src/components/RadioButtonGroup/RadioButtonGroup";
import {StandardRadioButton} from "designsystem/src/components/RadioButton/StandardRadioButton";
import {TextAreaRadioButton} from "designsystem/src/components/RadioButton/TextAreaRadioButton";
import {InfoBox} from "designsystem/src/components/InfoBox/InfoBox";
import {Button} from "designsystem/src/components/Button/Button";
import {CardGroup, CardGroupItem,} from "designsystem/src/components/CardGroup/CardGroup";
import {New, Redesign,} from "designsystem/src/components/Redesign/Redesign";
import {Badge} from "designsystem/src/components/Badge/Badge";
import {Spacing} from "designsystem/src/components/Spacing/Spacing";
import {Toggle} from "designsystem/src/components/Toggle/Toggle";
import {CardGroupHeader} from "designsystem/src/components/CardGroupHeader/CardGroupHeader";
import {useActiveTheme} from "designsystem/src/components/Redesign/activeTheme";
import {navigateToFidoDemo, navigateToFidoRegister, navigateToPasswordReset} from "../utils/navigationToFidoApps";

export interface FidoDataResponse {
  username: string;
  fastSignInReady: boolean;
  signInRegularly: boolean;
  authenticatorsByUpns: ReadonlyArray<AuthenticatorsByUpn>;
  fidoGroupInfo: FidoGroupProps;
}

export interface FidoRenameRequest {
  logicalKeyId: string;
  displayName: string;
}

export interface DeleteFidoRequest {
  logicalKeyId: string;
}

export interface AuthenticatorsByUpn {
  upn: string;
  keys: ReadonlyArray<Fido2Authenticator>;
}

export interface AuthenticatorListEntryPropsByUpn {
  upn: string;
  authenticators: AuthenticatorListEntryProps[];
}

export interface Fido2Authenticator {
  displayName: string;
  modelDisplayName: string;
  logicalKeyId: string;
  aaguid: string;
  active: boolean;
  createDate: string;
  modifyDate: string;
  lastUsedDate: string;
  createdWithRequireResidentKey: boolean;
  recentlyUsed: boolean;
}

export enum REASON_DISABLE_FIDO_ENFORCE {
  GLOBAL_PROTECT = "Global Protect Pre Logon VPN",
  ENROLL_INTUNE_DEVICE = "Enrollment new Intune device",
  OTHER = "Other",
}

export const AdvancedProtectionPage: FunctionComponent<{ user: UserInfo }> = ({
  user,
}) => {
  const [, setGlobalError] = useGlobalState("error");
  const { isOldTheme } = useActiveTheme();

  const { t } = useTranslation(["de", "en"]);
  const [fidoData, setFidoData] = useState<{
    username: string;
    fastSignInReady: boolean;
    signInRegularly: boolean;
    ownAuthenticators: AuthenticatorListEntryProps[];
    authenticatorListEntryPropsByUpns: AuthenticatorListEntryPropsByUpn[];
    fidoGroupProps?: FidoGroupProps;
  }>({
    username: "",
    fastSignInReady: false,
    signInRegularly: false,
    ownAuthenticators: [],
    authenticatorListEntryPropsByUpns: [],
    fidoGroupProps: undefined,
  });

  const [cookies] = useCookies();
  const [, setIsLoading] = useGlobalState("isLoading");
  const [modal, setModal] = useGlobalState("modal");
  const [isInFidoMigrationGroupState, setIsInFidoMigrationGroupState] =
    useState<boolean | undefined>(undefined);
  const [
    activeRadioValueDisableFidoEnforceReason,
    setActiveRadioValueDisableFidoEnforceReason,
  ] = useState<undefined | REASON_DISABLE_FIDO_ENFORCE>(undefined);
  const [
    otherReasonDisableFidoEnforcement,
    setOtherReasonDisableFidoEnforcement,
  ] = useState<string>();

  // oldestAllowedDate is 2019 12 31
  let oldestAllowedDate = new Date(1580477894294);

  //this extra loading indicator is for not showing the full side for a redirect (so it does not look like a reload of the side)
  const [initialLoad, setInitialLoad] = useState(false);

  const fetchFidoData = async () => {
    setIsLoading({ loadingType: "standard" });
    let res = undefined;
    try {
      const { status, error, payload } = await httpGet<FidoDataResponse>(
        `${apiPath}/user/fido`,
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
          (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return;
      }

      if (status == 200 && payload) {
        res = payload;
      }
    } finally {
      setIsLoading({ loadingType: null });
    }
    return res;
  };

  const updateFidoData = (data: FidoDataResponse) => {
    const authenticatorListEntryPropsByUpns: AuthenticatorListEntryPropsByUpn[] =
      data.authenticatorsByUpns.map((abu) => {
        const authenticatorListEntryProps: AuthenticatorListEntryProps[] =
          abu.keys.map((f2a) => ({
            type: identifyPlatformByDeviceName(f2a.displayName),
            name: f2a.displayName,
            creationDate: validateDateInformation(f2a.createDate),
            lastUsedDate: validateDateInformation(f2a.lastUsedDate),
            deviceType: f2a.modelDisplayName,
            createdWithRequireResidentKey: f2a.createdWithRequireResidentKey,
            recentlyUsed: f2a.recentlyUsed,
        saveNewName: (abu.upn === user.preferredUsername) ? (name: string) => renameFido(f2a.logicalKeyId, name) : undefined,
        onDelete: (abu.upn === user.preferredUsername) ? () => deleteFido(f2a.logicalKeyId) : undefined,
            isOnlyAuthenticator: abu.keys.length === 1,
            onCloseModal: onCloseModal,
            testAuthenticator: () => navigateToFidoDemo(abu.upn)
          }));

        return {
          upn: abu.upn,
          authenticators: sortAuthenticators(authenticatorListEntryProps),
        };
      });

    setIsInFidoMigrationGroupState(data?.fidoGroupInfo.isInFidoMigrationGroup);
    setFidoData({
      username: data.username,
      fastSignInReady: data.fastSignInReady,
      signInRegularly: data.signInRegularly,
      ownAuthenticators:
        authenticatorListEntryPropsByUpns.find(
          (v) => v.upn === user.preferredUsername,
        )?.authenticators || [],
      authenticatorListEntryPropsByUpns: authenticatorListEntryPropsByUpns,
      fidoGroupProps: data?.fidoGroupInfo,
    });
  };

  const sortAuthenticators = (
    authenticators: AuthenticatorListEntryProps[],
  ) => {
    return authenticators.sort((a, b) => {
      let date1 = getYoungerDateOfListEntry(a);
      let date2 = getYoungerDateOfListEntry(b);
      return date2.getTime() - date1.getTime();
    });
  };

  const getYoungerDateOfListEntry = (
    entry: AuthenticatorListEntryProps,
  ): Date => {
    // check if creationDate or lastUsedDate is the younger one
    if (entry.creationDate.valid && !entry.lastUsedDate.valid) {
      return new Date(entry.creationDate.date);
    } else if (!entry.creationDate.valid && entry.lastUsedDate.valid) {
      return new Date(entry.lastUsedDate.date);
    } else if (entry.creationDate.valid && entry.lastUsedDate.valid) {
      let val =
        new Date(entry.creationDate.date).getTime() >
        new Date(entry.lastUsedDate.date).getTime()
          ? entry.creationDate
          : entry.lastUsedDate;
      return new Date(val.date);
    }
    // return old date to put entry at the end of the list (has no effect on displayed information)
    return new Date("2000-01-01");
  };

  const validateDateInformation = (dateString: string) => {
    let result: AuthenticatorListDateProps = {
      valid: false,
      i18nKey: "",
      i18nValue: "",
      date: "",
    };
    // check if dateString value is a valid date
    if (isDateValid(dateString)) {
      result.valid = true;
      result.date = dateString;
      let i18nData = dateToAgeString(dateString);
      result.i18nKey = i18nData.key;
      result.i18nValue = i18nData.value || null;
    }
    return result;
  };

  const dateToAgeString = (
    dateString: string,
  ): { key: string; value: string | null } => {
    try {
      // calculate difference in days between currentDate and dateString
      let days = Math.floor(
        (Date.parse(new Date().toISOString()) - Date.parse(dateString)) /
          86400000,
      );
      let sameDay = checkForSameDay(new Date(), new Date(dateString));

      if (days == 0 && sameDay) {
        return { key: "portal.lastSeen.today", value: null };
      } else if (days == 0 && !sameDay) {
        return { key: "portal.lastSeen.yesterday", value: null };
      } else if (days == 1) {
        return { key: "portal.lastSeen.day", value: days.toString() };
      } else if (days <= 13) {
        return { key: "portal.lastSeen.days", value: days.toString() };
      }

      let weeks = Math.floor(days / 7);

      if (weeks <= 5) {
        return { key: "portal.lastSeen.weeks", value: weeks.toString() };
      }

      let months = Math.floor(days / 30);

      if (months == 1) {
        return { key: "portal.lastSeen.month", value: months.toString() };
      }

      return { key: "portal.lastSeen.months", value: months.toString() };
    } catch (e) {
      console.error(e);
    }

    // will end up in dateString being displayed, because it wont be resolved as an i18n key
    return { key: dateString, value: null };
  };

  const checkForSameDay = (firstDate: Date, secondDate: Date) => {
    return firstDate.getDay() === secondDate.getDay();
  };

  const onCloseModal = () => setModal(undefined);

  useEffect(() => {
    const initialLoad = async () => {
      const data = await fetchFidoData();
      const ownAuthenticators = data?.authenticatorsByUpns?.find(
        (v) => v.upn === user.preferredUsername,
      )?.keys;
      if (ownAuthenticators?.length === 0) {
        // skip portal, directly go to fido registration for own account
        navigateToFidoRegister(user, false)
      } else {
        setInitialLoad(true);
        if (data) {
          updateFidoData(data);
          if (ownAuthenticators?.length === 1 && isOldTheme) {
            setModal({
              type: "info",
              title: t("authenticationInformation.addAnother.headline"),
              children: (
                <OnlyOneAuthInfo
                  authType={identifyPlatformByDeviceName(
                    ownAuthenticators[0].modelDisplayName,
                  )}
                  authName={ownAuthenticators[0].displayName}
                />
              ),
              submitButtonLabel: t(
                "authenticationInformation.addAnother.addButton",
              ),
              onSubmit: () => navigateToFidoRegister(user, false), // we navigate to the normal fido registration page because the user has not enough own authenticators
              resetButtonLabel: t("button.close"),
              onClose: onCloseModal,
              backgroundLocked: true,
              submitButtonId: "authenticationInformation-add-another-submit",
              cancelButtonId: "authenticationInformation-add-another-cancel",
            });
          }
        }
      }
    };

    initialLoad();
  }, []);

  useEffect(() => {
    if (activeRadioValueDisableFidoEnforceReason !== undefined) {
      showModalDisableFidoEnforcement();
    }
  }, [
    activeRadioValueDisableFidoEnforceReason,
    otherReasonDisableFidoEnforcement,
  ]);

  const renameFido = async (logicalKeyId: string, displayName: string) => {
    setModal(undefined);
    setIsLoading({ loadingType: "standard" });
    try {
      const body: FidoRenameRequest = {
        logicalKeyId: logicalKeyId,
        displayName: displayName,
      };
      const { status, error, payload } = await httpPost<any>(
        `${apiPath}/user/fido/displayname`,
        body,
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
          (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return false;
      }
    } finally {
      setIsLoading({ loadingType: null });
    }

    let res = await fetchFidoData();
    if (res) {
      updateFidoData(res);
    }
    return true;
  };

  const deleteFido = async (logicalKeyId: string) => {
    setModal(undefined);
    setIsLoading({ loadingType: "standard" });
    try {
      const body: DeleteFidoRequest = {
        logicalKeyId: logicalKeyId,
      };
      const { status, error, payload } = await httpPost<any>(
        `${apiPath}/user/fido/delete`,
        body,
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
          (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return false;
      }
    } finally {
      setIsLoading({ loadingType: null });
    }

    let res = await fetchFidoData();
    if (res) {
      updateFidoData(res);
    }
    return true;
  };

  const enforceFido = async (
    enforceFidoValue: enforceFidoType,
    reason: string,
  ) => {
    setIsLoading({ loadingType: "standard" });
    try {
      const { status, error, payload } = await httpPost<any>(
        `${apiPath}/user/fido/enforce/${enforceFidoValue}`,
        { reason: reason },
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
          (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return false;
      }
    } finally {
      setIsInFidoMigrationGroupState(!isInFidoMigrationGroupState);
      setIsLoading({ loadingType: null });
    }

    let res = await fetchFidoData();
    if (res) {
      updateFidoData(res);
    }
    return true;
  };

  const disableFidoEnforcementReason = () => {
    return (
      <>
        <RadioButtonGroup className={"no-background"}>
          <I18NParagraph i18n={"disableFidoEnforcement.reason.headline"} />
          <StandardRadioButton
            className={"no-background"}
            label={t("disableFidoEnforcement.reason.globalProtect")}
            selected={
              activeRadioValueDisableFidoEnforceReason ===
              REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT
            }
            onClick={() => {
              setActiveRadioValueDisableFidoEnforceReason(
                REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT,
              );
              setOtherReasonDisableFidoEnforcement(undefined);
            }}
          />
          <StandardRadioButton
            className={"no-background"}
            label={t("disableFidoEnforcement.reason.newIntuneDevice")}
            selected={
              activeRadioValueDisableFidoEnforceReason ===
              REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE
            }
            onClick={() => {
              setActiveRadioValueDisableFidoEnforceReason(
                REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE,
              );
              setOtherReasonDisableFidoEnforcement(undefined);
            }}
          />
          <TextAreaRadioButton
            className={"no-background"}
            label={t("disableFidoEnforcement.reason.other")}
            selected={
              activeRadioValueDisableFidoEnforceReason ===
              REASON_DISABLE_FIDO_ENFORCE.OTHER
            }
            maxLength={200}
            onClick={() => {
              setActiveRadioValueDisableFidoEnforceReason(
                REASON_DISABLE_FIDO_ENFORCE.OTHER,
              );
            }}
            onTextChange={(prevOtherReason: string) =>
              setOtherReasonDisableFidoEnforcement(prevOtherReason)
            }
          />
        </RadioButtonGroup>
      </>
    );
  };

  const isFidoUsable = () => {
    return (
      fidoData.fidoGroupProps?.isInFidoEnabledGroup &&
      fidoData.ownAuthenticators.length >= 1
    );
  };

  const isFidoEnforced = () => {
    return (
      fidoData.fidoGroupProps?.isInFidoEnabledGroup &&
      !fidoData.fidoGroupProps.isInFidoMigrationGroup
    );
  };

  const isDateValid = (date: string): boolean => {
    let dateObj = Date.parse(date);

    return !(date === null || dateObj < oldestAllowedDate.getTime());
  };

  const getFidoEnforcementReason = (): string => {
    switch (activeRadioValueDisableFidoEnforceReason) {
      case REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT:
        return REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT.toString();
      case REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE:
        return REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE.toString();
      case REASON_DISABLE_FIDO_ENFORCE.OTHER:
        return otherReasonDisableFidoEnforcement ?? "";
    }
    return "None";
  };

  const showModalDisableFidoEnforcement = () => {
    setModal({
      type: "warning",
      onSubmit: () => {
        // Continue anyway we deactivate FIDO enforcement for the user
        enforceFido("disable", getFidoEnforcementReason());
        setModal(undefined);
      },
      onMagicBtn: () => {
        // Reset users own password first
        navigateToPasswordReset(user, false);
        setModal(undefined);
      },
      onClose: () => {
        setModal(undefined);
        setOtherReasonDisableFidoEnforcement(undefined);
        setActiveRadioValueDisableFidoEnforceReason(undefined);
      },

      title: t("portal.warning.fidoEnforcePasswordMayExpired.title"),
      children: (
        <>
          <I18NParagraph
            i18n={"portal.warning.fidoEnforcePasswordMayExpired.subtitle"}
          />
          {disableFidoEnforcementReason()}
        </>
      ),
      submitButtonLabel: t(
        "portal.warning.fidoEnforcePasswordMayExpired.button.continue",
      ),
      magicButtonLabel: t(
        "portal.warning.fidoEnforcePasswordMayExpired.button.resetPwd",
      ),
      submitButtonDisabled: !(
        (activeRadioValueDisableFidoEnforceReason ===
          REASON_DISABLE_FIDO_ENFORCE.OTHER &&
          (otherReasonDisableFidoEnforcement ?? "").trim().length > 0) ||
        activeRadioValueDisableFidoEnforceReason ===
          REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT ||
        activeRadioValueDisableFidoEnforceReason ===
          REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE
      ),
    });
  };

  const handleSetFidoEnforced = (value: boolean) => {
    let negateValue = !value;
    if (negateValue && fidoData.ownAuthenticators.length === 0) {
      // wants to be enforced but no fido devices are registered -> We warn the user
      setModal({
        type: "info",
        onSubmit: () => {
          // we enforce the user to use FIDO
          enforceFido("disable", "None");
          setModal(undefined);
        },
        onClose: () => {
          setModal(undefined);
        },
        title: t("portal.warning.fidoEnforceNoAuth.title"),
        children: (
          <I18NParagraph i18n={"portal.warning.fidoEnforceNoAuth.subtitle"} />
        ),
        submitButtonLabel: t(
          "portal.warning.fidoEnforceNoAuth.button.continue",
        ),
        resetButtonLabel: t("portal.warning.fidoEnforceNoAuth.button.cancel"),
        backgroundLocked: true,
      });
    } else if (!negateValue) {
      enforceFido("enable", "None");
    } else if (
      negateValue &&
      fidoData.fidoGroupProps?.isAllowedToDisableFidoEnforcement === false
    ) {
      // user wants to disable fido enforcement
      const passwordExpired = fidoData.fidoGroupProps?.isPasswordAlreadyExpired;

      setModal({
        type: "warning",
        onMagicBtn: () => {
          // Reset users own password first
          navigateToPasswordReset(user, false);
          setModal(undefined);
        },
        onClose: () => {
          setModal(undefined);
        },

        title: t(
          passwordExpired
            ? "portal.warning.fidoEnforcePasswordExpired.title"
            : "portal.warning.fidoEnforcePasswordExpiresSoon.title",
        ),
        children: (
          <I18NParagraph
            i18n={
              passwordExpired
                ? "portal.warning.fidoEnforcePasswordExpired.subtitle"
                : "portal.warning.fidoEnforcePasswordExpiresSoon.subtitle"
            }
          />
        ),
        submitButtonLabel: t(
          passwordExpired
            ? "portal.warning.fidoEnforcePasswordExpired.button.continue"
            : "portal.warning.fidoEnforcePasswordExpiresSoon.button.continue",
        ),
        magicButtonLabel: t(
          passwordExpired
            ? "portal.warning.fidoEnforcePasswordExpired.button.resetPwd"
            : "portal.warning.fidoEnforcePasswordExpiresSoon.button.resetPwd",
        ),
      });
    } else {
      // user wants to disable fido enforcement
      showModalDisableFidoEnforcement();
    }
  };

  const isInEnableAdvancedProtectionExclusionGroup = () => {
    return fidoData.fidoGroupProps?.isInEnableAdvancedProtectionExclusionGroup ?? false;
  };

  return (
    <div className={"portal"}>
      <div className="portal-main-wrapper">
        <div className="portal-main-content">
          <div className="portal-column">
            <Redesign>
              <New>
                {!fidoData.fastSignInReady && (
                  <InfoBox
                    type="info"
                    title={t("portal.securityCheckup.title")}
                  >
                    <p>
                      {t(
                        "portal.authenticationStatus.stepAuthentication.description",
                      )}
                    </p>
                    <Button
                      id="authenticationInformation-add-another-submit"
                      mode="yellow"
                      label="Create passkey"
                      onClick={() => navigateToFidoRegister(user, false)} // we navigate to the normal fido registration page because the user has not enough own authenticators
                    />
                  </InfoBox>
                )}
              </New>
            </Redesign>
            {initialLoad && (
                <div className="portal-column-item">
                  <Redesign>
                    <New>
                      <Spacing flexDirection="column" gap="24">
                        <CardGroupHeader
                          status={
                            !isInFidoMigrationGroupState
                              ? "success"
                              : "warning"
                          }
                          description={
                            !isInFidoMigrationGroupState
                              ? t("portal.securityCheckup.success")
                              : t("portal.securityCheckup.warning")
                          }
                          title={t("portal.securityCheckup.title")}
                        />
                        <CardGroup>
                          {isInFidoMigrationGroupState !== undefined && (
                            <CardGroupItem
                              id="hasFidoEnforcedStatus"
                              label={t(
                                "portal.securityCheckup.advancedProtection.headline",
                              )}
                              icon="shield-plus"
                              badge={
                                <Badge
                                  label={
                                    isInFidoMigrationGroupState ? "Off" : "On"
                                  }
                                  status={
                                    isInFidoMigrationGroupState
                                      ? "warning"
                                      : "success"
                                  }
                                />
                              }
                            >
                              <Spacing flexWrap="nowrap">
                                <span>
                                  {isInFidoMigrationGroupState
                                      ? ((isInEnableAdvancedProtectionExclusionGroup() && !isFidoEnforced()) ?
                                              t("portal.securityCheckup.advancedProtection.exclusionGroup") :
                                              t("portal.securityCheckup.advancedProtection.warning")
                                      )
                                      : t(
                                          "portal.securityCheckup.advancedProtection.success",
                                      )}
                                </span>
                                <Toggle
                                  style={{ flexGrow: 0 }}
                                  id="hasFidoEnforcedStatus-toggle"
                                  toggleState={!isInFidoMigrationGroupState}
                                  setToggleState={handleSetFidoEnforced}
                                  disabled={
                                    !(isFidoUsable() || isFidoEnforced()) || (isInEnableAdvancedProtectionExclusionGroup() && !isFidoEnforced())
                                  }
                                />
                              </Spacing>
                            </CardGroupItem>
                          )}
                        </CardGroup>
                      </Spacing>
                    </New>
                  </Redesign>
                </div>
            )}
          </div>
        </div>
      </div>

      <div className="mainWrapper">
        {modal && (
          <Modal
            type={modal.type}
            onSubmit={modal.onSubmit}
            title={modal.title}
            children={modal.children}
            submitButtonLabel={modal.submitButtonLabel}
            resetButtonLabel={modal.resetButtonLabel}
            onClose={modal.onClose}
            backgroundLocked={true}
            submitButtonId={modal.submitButtonId}
            cancelButtonId={modal.cancelButtonId}
            onMagicBtn={modal.onMagicBtn}
            magicButtonId={modal.magicButtonId}
            magicButtonLabel={modal.magicButtonLabel}
            submitButtonDisabled={modal.submitButtonDisabled}
          />
        )}
      </div>
    </div>
  );
};
