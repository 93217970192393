import clsx from "clsx";
import { UntitledColorizeIcon } from "../icons";
import { Spacing } from "../Spacing";
import { CardGroupItemProps, CardGroupProps } from "./CardGroup.interface";
import "./cardGroup.scss";

const CardGroup: React.FC<CardGroupProps> = ({ children }) => (
  <Spacing flexDirection="column" className="card-group">
    {children}
  </Spacing>
);

const CardGroupItem: React.FC<CardGroupItemProps> = ({
  label,
  icon,
  description,
  children,
  badge,
  disabled = false,
  id,
  buttonType = undefined,
  className,
  onClick,
}) => (
  <Spacing
    Comp={onClick ? "button" : "div"}
    type={buttonType}
    className={clsx("card-group-item", {
      "is-clickable": !disabled && onClick,
    }, className)}
    flexWrap="nowrap"
    alignItems={description || children ? "start" : "center"}
    gap="8"
    id={!onClick ? id : undefined}
    onClick={!disabled ? onClick : undefined}
    style={{
      cursor: !disabled && onClick ? "pointer" : undefined,
      opacity: disabled ? 0.5 : 1,
    }}
  >
    {icon && <UntitledColorizeIcon name={icon} />}
    <Spacing
      flexDirection="column"
      alignItems="center"
      gap={description || children ? "8" : "0"}
    >
      <Spacing alignItems="center" gap="4">
        {id?
            <span id={id+"-label"} className="label">{label}</span>:
            <span className="label">{label}</span>
        }
        {badge}
      </Spacing>
      <div className="description">{children || description}</div>
    </Spacing>
    {!disabled && onClick && (
      <button id={id} className="action-wrapper" type={buttonType}>
        <UntitledColorizeIcon name="chevron-right" />
      </button>
    )}
  </Spacing>
);

export { CardGroup, CardGroupItem };
